<template>
  <div class="page-schedule">
    <div class="schedule-header">
      <v-container class="schedule-header__container">
        <div class="schedule-header__bg-content">
          <div class="schedule-header__bg-text">
            <span>
              Ocean <br>
              of possibilities
            </span>
          </div>
          <!-- <div class="schedule-header__bg-slogan">
            Tagline of possibilities
          </div> -->
        </div>
        <div class="schedule-header-logo-container">
          <div class="schedule-header-logo">
            <img :src="require('@/assets/img/svg/forum_logo.svg')" alt="">
          </div>
        </div>
        <div class="schedule-header-text-conteiner">
          <div class="schedule-header-text">
            <!-- Элемент дизайна, не требует перевода -->
            SCHEDULE
          </div>
        </div>
      </v-container>
    </div>

    <Schedule />
    <Speakers />
  </div>
</template>

<script>
import Schedule from './containers/Schedule'
import Speakers from './containers/Speakers'
import store from './store'
import { mapActions } from 'vuex'
import EventBus from '@/EventBus'

export default {
  store,

  components: {
    Schedule,
    Speakers
  },

  async mounted () {
    await this.getContent()
    setTimeout(() => {
      this.scrollFix()
    }, 0)

    EventBus.subscribe('language:update', this.getContent)
  },

  beforeDestroy () {
    EventBus.unsubscribe('language:update', this.getContent)
  },

  methods: {
    ...mapActions(['getContent']),

    scrollFix () {
      if (!this.$route.hash) return
      const element = document.querySelector(this.$route.hash)
      window.scrollTo(0, element.offsetTop)
    }
  }
}
</script>
