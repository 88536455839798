<template>
  <div
    id="mob_schedule_list"
    class="mob_schedule-content"
  >
    <template v-if="schedule">
      <div
        v-for="day in schedule"
        :id="`mob_day_${day.dayNumber}`"
        :key="day.dayNumber"
        class="mob_schedule-content-list"
      >
        <div class="mob_schedule-content-header">
          <hr class="mob_schedule-content-header-line">

          <div class="mob_schedule-content-header-day-text">
            {{ $t('day') }} {{ day.dayNumber }}
          </div>
        </div>

        <div class="schedule-content-days">
          <ListSchedule
            :items="day.items"
            :show-description="true"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ListSchedule from '@/components/ListSchedule'

export default {
  components: {
    ListSchedule
  },

  props: {
    schedule: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
